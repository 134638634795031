import React from "react";
import Layout from "templates/basic/";
import Intro from 'components/intro'
import SEO from 'components/seo';
import Blockquote from 'components/blockquote/';
import { graphql } from 'gatsby';
import contentStyle from 'templates/ContentPageGet/ContentPageGet.module.scss';
import style from "templates/basic/careers.module.scss";
import Accordion from 'components/accordion';
import Card from 'components/card';
import parse from 'html-react-parser';

const CareersPage = ({ data, pageContext }) => {
	let page = null;
	if (data.allContentPage && data.allContentPage.edges.length) page = data.allContentPage.edges[0].node;
	const trainingCards = page.trainingCards;
	const trainingPrograms = page.trainingPrograms;
	return (
		<>
			<Layout image={page.headerImage} preTitle={page.pageTitle} title={page.subTitle} link={page.link} linkText={page.linkText} enableDanosFoundation={page.danosFoundation} enableFooterTabs={page.danosFooterTab}>
				<Intro pullQuote={page.pullQuote} headline={page.headline} pageCopy={page.pageCopy} />
				<div className="grid-container">
					<div className="grid-x grid-margin-x">
						<div className="cell small-12">
							{page.content.main && <hr style={{ margin: "0px auto 60px" }} />}
							<div className={contentStyle.innerContent}>{page ? parse(page.content.main) : ''}</div>
						</div>
					</div>
				</div>
				<section className={style.featuredContentWrapper}>
					<div className="grid-container">
						<div className="grid-x grid-margin-x">
							<div className="cell small-12 large-7">
								<div className={style.featuredContent}>{page ? parse(page.featuredContent) : ''}</div>
							</div>
							<div className={`${style.accordionWrapper} cell small-12 large-5`}>
								{trainingPrograms && trainingPrograms.map((program, index) => {
									return (
										<Accordion key={index} title={program.title} body={program.body} />
									)
								})}
							</div>
						</div>
					</div>
					<div className={`${style.trainingCardsGrid} grid-container`}>
						<div className={`${style.trainingCardsRow} grid-x grid-padding-x small-up-1 large-up-4`}>
							{trainingCards && trainingCards.map((card, index) => {
								return (
									<Card type='Training' index={index} key={index} data={card} />
								)
							})}
						</div>
					</div>
				</section>
				{page.bottomContent.length && <section className={style.bottomContentWrapper}>
					<div className="grid-container">
						<div className="grid-x grid-margin-x">
							<div className="cell small-12 large-9">
								<div className={style.bottomContent}>{page ? parse(page.bottomContent) : ''}</div>
							</div>
						</div>
					</div>
				</section>}
			</Layout>
			<SEO title="Danos Careers." />
		</>
	)
}

export const query = graphql`
	query ($lang: String) {
	  allContentPage(filter: {uri: {eq: "/careers/"}, lang: {eq: $lang}, deleted: {eq: false}, archived: {eq: false}, approved: {eq: true}}) {
	    edges {
	      node {
	        id
	        uri
	        content {
	          main
	        }
			lang
	        pageTitle
			subTitle
			pullQuote
			pageCopy
			headline
			link
			linkText
			danosFoundation
			danosFooterTab
			bottomContent
			featuredContent
			headerImage
			trainingPrograms
	      }
	    }
	  }
	}
`

export default CareersPage