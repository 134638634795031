import React, { Component } from "react"
import PropTypes from "prop-types"
// import { useStaticQuery, graphql } from "gatsby"

import Footer from '../../components/footer/';
import Header from '../../components/header/';
import SEO from '../../components/seo';
import Offcanvas from '../../components/offcanvas/';
import style from '../home/home.module.scss';
import Background from '../../components/background';
import Link from '../../components/link';

class Layout extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isOpen: false,
		}
		this.open = this.open.bind(this);
		this.close = this.close.bind(this);
	}

	componentWillUnmount() {
		this.setState({ isOpen: false });
	}

	open() {
		this.setState({ isOpen: true });
	}

	close() {
		this.setState({ isOpen: false });
	}

	render() {
		return (
			<>
				<SEO>
					<link rel="stylesheet" href="https://use.typekit.net/uac4quh.css" />
				</SEO>
				<Offcanvas isOpen={this.state.isOpen} offcanvasOpen={this.open} offcanvasClose={this.close} >
					<Header spacer={false} offcanvasOpen={this.open} offcanvasClose={this.close} offcanvasState={this.state.isOpen} />
					<Background className={style.background} image={this.props.image ? this.props.image : "Danos-Default-Placeholder-v2.jpg"}>
						<div className={`${style.grid} grid-container`}>
							<div className="grid-x grid-margin-x">
								<div className="cell small-12 medium-5">
									<Background className={style.headline} overlay="linear-gradient(180deg, rgba(68,85,90,0) 0%, #050505 100%)" style={{ backgroundRepeat: "repeat", backgroundSize: "auto", width: "calc(100% + 66px)" }} image="Repeatable-Texture.png">
										<div className={style.headlineWrapper}>
											<span>{this.props.preTitle}</span>
											<h1>{this.props.title}</h1>
											<div className={[style.subline, "text-right"].join(" ")}>
												{ this.props.link && (
													<Link className="button small" href={this.props.link}>{this.props.linkText}</Link>
												)}
											</div>
										</div>
									</Background>
								</div>
							</div>
						</div>
					</Background>
					<main>{this.props.children}</main>
					<Footer enableDanosFoundation={this.props.enableDanosFoundation} enableFooterTabs={this.props.enableFooterTabs} />
				</Offcanvas>
			</>
		)
	}
}

Layout.propTypes = {
	children: PropTypes.node.isRequired,
}

export default Layout
